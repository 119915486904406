import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import OperationsLayout from "./Components/OperationsLayout";
import ProtectedRoute from "../../../Components/Nav/ProtectedRoute";
import urls from "../../../../Utils/URLs";
import DataCollectionPage from "./Scenes/DataCollection/DataCollectionPage";
import PageNotFound from "../../../PageNotFound";
import CampaignLaunchPage from "./Scenes/CampaignLaunch/CampaignLaunchPage";
import ProductEnrollmentPage from "./Scenes/ProductEnrollment/ProductEnrollmentPage";
import ProductBudgetUpdatePage from "./Scenes/ProductBudgetUpdate/ProductBudgetUpdatePage";
import UserAuthorizationPage from './Scenes/UserAuthorization/UserAuthorizationPage';
import SellerPartnerDataPage from './Scenes/SellerPartnerData/SellerPartnerDataPage';
import AdvertisingDataPage from './Scenes/AdvertisingData/AdvertisingDataPage';
import AdvertisingJobPage from './Scenes/AdvertisingJobs/AdvertisingJobPage';
import OpsDashTicketListPage from './Scenes/opsDashTickets/OpsDashTicketListPage';
import WorkflowsMainPage from './Scenes/Workflows/WorkflowsMainPage';
import StoreHealthMainPage from './Scenes/StoreHealth/StoreHealthMainPage';
import OpsResourceSkillSetPage from './Scenes/ResourceSkillSet/OpsResourceSkillSetPage';
import MyTasksMainPage from './Scenes/My Tasks/MyTasksMainPage';
import TicketAssignMainPage from './Scenes/TicketAssign/TicketAssignMainPage';
function OperationsPage(props) {
    return (
        <OperationsLayout>
            <Switch>

                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:dataCollection"
                    exact={true}
                    path={urls.OPS_DATA_COLLECTION_URL}
                >
                    <DataCollectionPage />
                </ProtectedRoute>

                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:campaignLaunch"
                    exact={true}
                    path={urls.OPS_CAMPAIGN_LAUNCH_URL}
                >
                    <CampaignLaunchPage />
                </ProtectedRoute>

                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:productEnrollment"
                    exact={true}
                    path={urls.OPS_PRODUCT_ENROLLMENT_URL}
                >
                    <ProductEnrollmentPage />
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:productBudgetUpdate"
                    exact={true}
                    path={urls.OPS_PRODUCT_BUDGET_UPDATE_URL}
                >
                    < ProductBudgetUpdatePage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:userAuthorization"
                    exact={true}
                    path={urls.OPS_USER_AUTHORIZATION_URL}
                >
                    < UserAuthorizationPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:sellerPartnerData"
                    exact={true}
                    path={urls.OPS_SELLER_PARTNER_DATA_URL}
                >
                    < SellerPartnerDataPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:advertisingData"
                    exact={true}
                    path={urls.OPS_ADVERTISING_DATA_URL}
                >
                    < AdvertisingDataPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:advertisingJob"
                    exact={true}
                    path={urls.OPS_ADVERTISING_JOB_URL}
                >
                    <AdvertisingJobPage/>
                    </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:ticketList"
                    exact={true}
                    path={urls.OPS_TICKETLIST_URL}
                >
                    {/* <TicketMainPage /> */}
                    <OpsDashTicketListPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:resourceSkillSet"
                    exact={true}
                    path={urls.OPS_RESOURCESKILLSET_URL}
                >
                    {/* <TicketMainPage /> */}
                    <OpsResourceSkillSetPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:ticketAssign"
                    exact={true}
                    path={urls.OPS_TICKETASSIGN_URL}
                >
                    <TicketAssignMainPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:workflows"
                    exact={true}
                    path={urls.OPS_WORKFLOWS_URL}
                >
                    {/* <TicketMainPage /> */}
                    <WorkflowsMainPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:myTasks"
                    exact={true}
                    path={urls.OPS_MYWORKS_URL}
                >
                    {/* <TicketMainPage /> */}
                    <MyTasksMainPage/>
                </ProtectedRoute>
                <ProtectedRoute
                    role={props.accessLevel}
                    perform="ops:storeHealth"
                    exact={true}
                    path={urls.OPS_STOREHEALTH_URL}
                >
                    {/* <TicketMainPage /> */}
                    <StoreHealthMainPage/>
                </ProtectedRoute>
                <Route component={PageNotFound} />

            </Switch>
        </OperationsLayout>
    )
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
});

export default connect(mapStateToProps)(OperationsPage);