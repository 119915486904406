import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
// import FetchAllCategoriesService from '../../../../../../../../Service/DashboardServices/AssetGalleryServices/FetchAllCategoriesService';
import { Button, Card, Col, Row } from 'reactstrap';
import AssetsGallery_Thin from '../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/AssetsGallery_Thin.svg'
import BackButton from "../../../../.././../../../designUtils/Logo/BackButton.svg"
import BrandDetails from './Components/BrandDetails';
import ProductDetails from './Components/ProductDetails';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loaders";
import FetchAllCategoryAndSubCategory from '../../../../../../../../Service/DashboardServices/AssetGalleryServices/FetchAllCategoryAndSubCategoryService';
function AssetGalleryMain(props) {
  const [category, setCategory] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [subCategory, setSubCategory] = useState()
  const [categoryID, setCategoryID] = useState(1)
  const [loadScreen, setLoadScreen] = useState(false)
  useEffect(() => {
    setLoadScreen(true)
    FetchAllCategoryAndSubCategory(props.token).then((response) => {
      if (response) {
        console.log(response);
        setCategory(response.category)
        setSubCategory(response.subCategory)
        setLoadScreen(false)
      }
    }
    );
  }, []);

  return (
    <>
      <Card className='p-2 mb-2 child-card' >

        <Col md={12}>
          <h4>
            <button onClick={() => { props.goBackPA() }} className="hover-btn-link back-btn mr-1 mb-3 "  >
              <img src={BackButton} alt="not found" />
            </button>
            <img src={AssetsGallery_Thin} alt=' not found' />
            &nbsp; <b>Asset Gallery</b></h4>
          <hr className='line'></hr>
        </Col>
        <Col md={12}>
          <div className='d-flex'>
            {
              category && category.map((cat, index) => (

                <>
                  {/* {selectedTab === false && index === 0 ? setSelectedTab(pa.id) : ''} */}
                  <h5
                    // className='ml-2'
                    className={`ml-2 ${cat.id === categoryID ? 'selected-tab-assets-tab ' : 'assets-tab-not-selected'} `}
                    onClick={() => { setCurrentPage(cat.id); setCategoryID(cat.id) }}
                  >
                    <b>{cat.categoryName}</b>

                  </h5>
                </>))
            }
          </div>
        </Col>
      </Card>
      <LoadingOverlay
        active={loadScreen}
        styles={{
          overlay: (base) => ({
            ...base,
            background: '#f1f4f6',
            opacity: 1,
          }),
          content: (base) => ({
            ...base,
            color: '#000',
          }),
        }}
        spinner={<Loader active type="ball-pulse" />}
        text="Loading asset details..."
      >

        {currentPage === 1 && <BrandDetails subCategory={subCategory} catId={categoryID} />}
        {currentPage === 2 && <ProductDetails subCategory={subCategory} catId={categoryID} />}
      </LoadingOverlay>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};

export default connect(mapStateToProps)(AssetGalleryMain);