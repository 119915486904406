import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
// import notify from '../../Notifications/ToastifyActions';
import notify from '../../../Notifications/ToastifyActions';

function UpdateProblemAreaAndTypeServices(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/swhelpdesk/UpdateProblemAreaAndType`, data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( ).
        then(res => {
            notify.NotifySuccess("Problem area, Type & Subtype data has been updated successfully. Thank You!"); 
            return res.data;
        }).catch(error => {  
            console.log(error)            
            notify.NotifyError("An error occurred while generating report, please try again later.");
            return false;
        
        });
}
export {
    UpdateProblemAreaAndTypeServices,
}