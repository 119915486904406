import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function UpdateNotificationStatus(token,data) {

    return axios.post(`${CURRENT_API_URL}/account/updateNotificationStatus`,data, 
    {
        headers: {
                    'Authorization': token,
            }
    }).then((res) => {
        return (res.data);
    }).catch(() => {
        notify.NotifyError("Something went wrong while fetching data.");
        return false;
    })
}

export default UpdateNotificationStatus;