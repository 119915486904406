import React from 'react';
import { connect } from 'react-redux';

import {filterByPermission} from "../../../../Components/Can";
import DashboardLayout from "../../../Layout/DashboardLayout";
import urls from "../../../../../Utils/URLs";


function OperationsLayout(props) {

    let menuItems = [
        // {
        //     perform: "ops:dataCollection",
        //     yes: {
        //         icon: 'pe-7s-server',
        //         label: 'Data Collection',
        //         to: urls.OPS_DATA_COLLECTION_URL,
        //     }
        // },
        {
            perform: "ops:campaignLaunch",
            yes: {
                icon: 'pe-7s-rocket',
                label: 'Campaign',
                to: urls.OPS_CAMPAIGN_LAUNCH_URL,
            }
        },
        // {
        //     perform: "ops:productEnrollment",
        //     yes: {
        //         icon: 'pe-7s-notebook',
        //         label: 'Product Enrollment',
        //         to: urls.OPS_PRODUCT_ENROLLMENT_URL,
        //     }
        // },
        // {
        //     perform: "ops:productBudgetUpdate",
        //     yes: {
        //         icon: 'pe-7s-cash',
        //         label: 'Product Budget Update',
        //         to: urls.OPS_PRODUCT_BUDGET_UPDATE_URL,
        //     }
        // },
        // {
        //     perform: "ops:userAuthorization",
        //     yes: {
        //         icon: 'pe-7s-users',
        //         label: 'User Authorization',
        //         to: urls.OPS_USER_AUTHORIZATION_URL,
        //     }
        // },
        // {
        //     perform: "ops:sellerPartnerData",
        //     yes: {
        //         icon: 'pe-7s-display2',
        //         label: 'Seller Partner Data',
        //         to: urls.OPS_SELLER_PARTNER_DATA_URL,
        //     }
        // },
        // {
        //     perform: "ops:advertisingData",
        //     yes: {
        //         icon: 'pe-7s-display2',
        //         label: 'Advertising Reports Data',
        //         to: urls.OPS_ADVERTISING_DATA_URL,
        //     }
        // },
        {
            perform: "ops:advertisingJob",
            yes: {
                icon: 'pe-7s-cash',
                label: 'Prod Support',
                to: urls.OPS_ADVERTISING_JOB_URL,
            }
        },
        {
            perform: 'ops:workflows',
            yes: {
                icon: 'pe-7s-notebook',
                label: 'Workflows',
                to: urls.OPS_WORKFLOWS_URL,
            }
        },
        // {
        //     perform: 'ops:ticketList',
        //     yes: {
        //         icon: 'pe-7s-display2',
        //         label: 'Ticket List',
        //         to: urls.OPS_TICKETLIST_URL,
        //     }
        // },
        {
            perform: 'ops:myTasks',
            yes: {
                icon: 'pe-7s-display2',
                label: 'My Tasks',
                to: urls.OPS_MYWORKS_URL,
            }
        },
        {
            perform: 'ops:storeHealth',
            yes: {
                icon: 'pe-7s-like2',
                label: 'Store Health',
                to: urls. OPS_STOREHEALTH_URL,
            }
        },
        {
            perform: 'ops:resourceSkillSet',
            yes: {
                icon: 'pe-7s-graph1',
                label: 'Resource Skill Set',
                to: urls.OPS_RESOURCESKILLSET_URL,
            }
        },
        {
            perform: 'ops:ticketAssign',
            yes: {
                icon: 'pe-7s-note2',
                label: 'Ticket Assignment',
                to: urls.OPS_TICKETASSIGN_URL,
            }
        },

    ];

    menuItems = filterByPermission(menuItems, props.accessLevel);

    return (
        <DashboardLayout
            menuItems={menuItems}
            heading="Operations Monitoring"
            icon="lnr-map text-info"
            subheading=""
        >
            {props.children}
        </DashboardLayout>
    )
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(OperationsLayout);