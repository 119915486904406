import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { connect } from "react-redux";
import GetUserProfileDataService from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/GetUserProfileDataService";
import linkedin from "./../../../../../assets/img/UserProfile_Img/linkedin.svg";
import instagram from "./../../../../../assets/img/UserProfile_Img/instagram.svg";
import facebook from "./../../../../../assets/img/UserProfile_Img/facebook.svg";
import vector from "./../../../../../assets/img/UserProfile_Img/vector.svg";
import checkbox from "./../../../../../assets/img/UserProfile_Img/checkbox.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUser, faSave } from "@fortawesome/free-solid-svg-icons";
import UpdateSocialMediaUrlsService from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/UpdateSocialMediaUrlsService";
import UpdateUserProfileDataService from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/UpdateUserProfileDataService";
import ChangeUserPasswordService from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/ChangeUserPasswordService";
import GetUserNotificationDataService from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/GetUserNotificationDataService";
import UpdateNotificationStatus from "../../../../../Service/DashboardServices/UserProfileServices/ProfileDataServices/UpdateNotificationStatus";
function PersonalInfo(props) {
  const [companyNameError, setCompanyNameError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isUpdatePSWUpdateOpen, setIsUpdatePSWUpdateOpen] = useState(false);
  const [editBtnClicked, setEditBtnClicked] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isPopUpOpenText, setIsPopUpOpenText] = useState("");
  const [isFacebookValidUrl, setFacebookValidUrl] = useState(true);
  const [isInstagramValidUrl, setInstagramValidUrl] = useState(true);
  const [isLinkedinValidUrl, setLinkedinValidUrl] = useState(true);
  // const [validationError, setValidationError] = useState(true);
  const [isEnableFlag,setIsEnableFlag]=useState({'event':'','isEnable': false})
  const [saveButton,setSaveButton]= useState(false)
  const [socialMediaUrls, setSocialMediaUrls] = useState({
    twitterurl: "",
    facebookurl: "",
    youtubeurl: "",
    instagramurl: "",
    linkedinurl: "",
  });
  const [preferences, setPreferences] = useState({
    Create: false ,
    TicketUpdated: false ,
    Needinfo: true,
    Closed:false,
    Resolved: false,
    Informed: true,
    Assigned:true,
    AmazonCaseRaised: false,
    AmazonResponseReceived: false,
  });
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });
useEffect(() => {
  GetUserNotificationDataService(props.token).then((res) => {
    if (res && res.actualDict) {
      console.log(res)
      const updatedPreferences = res.actualDict.reduce((acc, item) => {
        const key = item.event.replace(/\s+/g, ''); // Remove spaces from event name
        acc[key] = item.isEnable;
        return acc;
      }, {});
      console.log(updatedPreferences)
      setPreferences((prevPreferences) => ({
        ...prevPreferences,
        ...updatedPreferences,
      }));
    }
  });
}, []);

  const handleImageClick = (socialMedia) => {
    setIsPopUpOpenText(`Enter ${socialMedia} URL:`);
    setIsPopUpOpen(true);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const isValidFirstName =
      /^[A-Za-z]+$/.test(userData.firstName) && userData.firstName !== "";
    const isValidLastName =
      /^[A-Za-z]+$/.test(userData.lastName) && userData.lastName !== "";
    const isValidPhone =
      /^[0-9]+$/.test(userData.phone) &&
      userData.phone !== null &&
      userData.phone.length === 10;

    // Check if there are any validation errors
    if (!isValidFirstName || !isValidLastName || !isValidPhone) {
      console.log("Validation errors. Data not saved.");
      setFirstNameError(
        isValidFirstName ? "" : "Please provide a valid first name."
      );
      setLastNameError(
        isValidLastName ? "" : "Please provide a valid last name."
      );
      setPhoneError(isValidPhone ? "" : "Please provide a valid phone number.");
      // setValidationError(false);
      return;
    }
    const data = userData;
    UpdateUserProfileDataService(props.token, data).then((response) => {
      if (response) {
        // setUserData({
        //   facebookurl: response.facebookurl,
        //   instagramurl: response.instagramurl,
        //   linkedinurl: response.linkedinurl,
        // });
        setEditBtnClicked(false);
        setShowConfirmationPopup(false);
        // setValidationError(true);
        window.location.reload(false);

      }
    });
    setEditBtnClicked(false);
  };
  const handleSocilMediaFormSubmit = (event) => {
    event.preventDefault();
    console.log("Social Media URLs:", socialMediaUrls);
    const data = socialMediaUrls;
    UpdateSocialMediaUrlsService(props.token, data).then((response) => {
      if (response) {
        setSocialMediaUrls({
          facebookurl: response.facebookurl,
          instagramurl: response.instagramurl,
          linkedinurl: response.linkedinurl,
        });
        setEditBtnClicked(false);
      }
    });
    setIsPopUpOpen(false);
  };

  const updateNotificationStatusData = (moduleName) =>{
    let data = {
      'moduleName':moduleName,
      'Create': preferences.Create,
      'Ticket Updated':preferences.TicketUpdated,
      'Closed': preferences.Closed,
      'Resolved': preferences.Resolved,
      'Informed': preferences.Informed,
      'Need info': preferences.Needinfo,
      'Assigned': preferences.Assigned,
      'Amazon Case Raised':preferences.AmazonCaseRaised,
      'Amazon Response Received':preferences.AmazonResponseReceived
    }
    setSaveButton(true)
    UpdateNotificationStatus(props.token,data).then((res)=>{
      if(res)
      {
        console.log(res);
    setSaveButton(false)

      }
    })
  }
  const handleCheckboxChange = (option) => {
    console.log(preferences)
    console.log(`Toggling ${option}:`, !preferences[option]);
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [option]: !prevPreferences[option],
    }));
  };

  const handleInputDataChange = (e) => {
    const { name, value } = e.target;

    // Alphabetic validation
    const isValidName = /^[A-Za-z]+$/.test(value) || value === "";
    const isValidPhone =
      /^[0-9]+$/.test(value) && value !== null && value.length == 10;

    if (name === "companyName") {
      setUserData({ ...userData, [name]: value });
    }
    if (name === "firstName") {
      setUserData({ ...userData, [name]: value });
      setFirstNameError(
        value === ""
          ? "Please provide valid name."
          : isValidName
          ? ""
          : "Only alphabetical characters are allowed."
      );
    }

    if (name === "lastName") {
      setUserData({ ...userData, [name]: value });
      setLastNameError(
        value === ""
          ? "Please provide valid name."
          : isValidName
          ? ""
          : "Only alphabetical characters are allowed."
      );
    }
    if (name === "phone") {
      setUserData({ ...userData, [name]: value });
      setPhoneError(
        value === ""
          ? "Please provide a valid phone number."
          : isValidPhone
          ? ""
          : "Only numeric characters are allowed and length should be 10 digit."
      );
    }
  };
  const handleInputChange = (platform, value) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const isValidUrl = urlRegex.test(value);
    if (platform === "facebookurl") {
      if (isValidUrl) {
        setFacebookValidUrl(isValidUrl);

        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
      } else {
        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
        setFacebookValidUrl("Please provide valid URL.");
      }
    }
    if (platform === "instagramurl") {
      if (isValidUrl) {
        setInstagramValidUrl(isValidUrl);

        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
      } else {
        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
        setInstagramValidUrl("Please provide valid URL.");
      }
    }
    if (platform === "linkedinurl") {
      if (isValidUrl) {
        setLinkedinValidUrl(isValidUrl);

        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
      } else {
        setSocialMediaUrls((prevUrls) => ({
          ...prevUrls,
          [platform]: value,
        }));
        setLinkedinValidUrl("Please provide valid URL.");
      }
    }
  };

  useEffect(() => {
    GetUserProfileDataService(props.token).then((response) => {
      if (response) {
        console.log(response);
        setUserData({
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          phone: response.phone,
          companyName: response.companyName,
        });
        setSocialMediaUrls({
          facebookurl: response.facebookurl,
          instagramurl: response.instagramurl,
          linkedinurl: response.linkedinurl,
        });
      }
    });
  }, []);
  const handleEditForm = () => {
    setEditBtnClicked(true);
  };
  const handleSaveButtonClick = () => {
    setShowConfirmationPopup(true);
  };
  const handleConfirmation = () => {
    // Save data only when confirmation is given

    setEditBtnClicked(false);
    window.location.reload();
  };
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  
  const handleUpdatePassword = (event) => {
    event.preventDefault();
  
    // Perform validation here
    if (newPassword !== confirmNewPassword) {
      alert("New password and confirm password dosen't match.");
      return;
    }
    const data = {
      oldPassword:oldPassword,
      newPassword:newPassword
    }
    ChangeUserPasswordService(props.token,data).then((res)=>{
      if(res.status == true)
      {
        setIsUpdatePSWUpdateOpen(false)
      }
  
    })
    // Call your API to change the password
    // For demonstration purposes, we'll just log the data
    console.log('Old Password:', oldPassword);
    console.log('New Password:', newPassword);
    console.log('Confirm New Password:', confirmNewPassword);
  
    // Reset the form
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };
  
  return (
    <>
      <Container id="personal">
        <Row className="mb-2">
          <Col md={12} className="page-header1">
            <Card>
              <CardBody style={{ padding: "10px" }}>
                <h3 className=" pb-0">
                  <FontAwesomeIcon icon={faUser} /> &nbsp;User Profile{" "}
                  {editBtnClicked ? (
                    <span
                      className="float-right"
                      onClick={handleSaveButtonClick}
                    >
                      <FontAwesomeIcon
                        icon={faSave}
                        className="edit-icon"
                        title="Save"
                      />
                    </span>
                  ) : (
                    <span className="float-right" onClick={handleEditForm}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="edit-icon"
                        title="Edit"
                      />
                    </span>
                  )}
                </h3>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="">
          <Col xl={"12"} lg={"8"} md={"12"}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={12} id="user-profile">
                    <div className="">
                      <h1 className="heading">
                        <span className="heading-text">
                          Personal Information
                        </span>
                      </h1>
                      {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" /> */}
                    </div>
                  </Col>
                  <table className="user-profile-table">
                    <tbody>
                      <tr>
                        <td className="user-profile-label">Company Name :</td>
                        <td className="user-profile-content">
                          {editBtnClicked ? (
                            <>
                              <input
                                id="companyName"
                                name="companyName"
                                value={userData ? userData.companyName : ""}
                                onChange={(e) => handleInputDataChange(e)}
                              />
                              {companyNameError && (
                                <span className="validation-text">
                                  {companyNameError}
                                </span>
                              )}
                            </>
                          ) : userData ? (
                            userData.companyName
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="user-profile-label">
                          First Name <span style={{ color: "red" }}>*</span> :
                        </td>
                        <td className="user-profile-content">
                          {editBtnClicked ? (
                            <>
                              <input
                                id="firstName"
                                name="firstName"
                                value={userData ? userData.firstName : ""}
                                onChange={(e) => handleInputDataChange(e)}
                              />
                              {firstNameError && (
                                <span className="validation-text">
                                  {firstNameError}
                                </span>
                              )}
                            </>
                          ) : userData ? (
                            userData.firstName
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="user-profile-label">
                          Last Name <span style={{ color: "red" }}>*</span> :
                        </td>
                        <td className="user-profile-content">
                          {editBtnClicked ? (
                            <>
                              <input
                                id="lastName"
                                name="lastName"
                                value={userData ? userData.lastName : ""}
                                onChange={(e) => handleInputDataChange(e)}
                              />
                              {lastNameError && (
                                <span className="validation-text">
                                  {lastNameError}
                                </span>
                              )}
                            </>
                          ) : userData ? (
                            userData.lastName
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={"12"} lg={"8"} md={"12"}>
            <Card className="" id="contact">
              <CardBody>
                <Row>
                  <Col md={12} id="user-profile">
                    <div className="">
                      <h2 className="heading">
                        <span className="heading-text">
                          Contact Information
                        </span>
                      </h2>
                      {/* <FontAwesomeIcon icon={faEdit} className="edit-icon" /> */}
                    </div>
                  </Col>
                  <table className="user-profile-table">
                    <tbody>
                      <tr>
                        <td className="user-profile-label">E-mail :</td>
                        <td className="user-profile-content">
                          {userData ? userData.email : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="user-profile-label">
                          Phone <span style={{ color: "red" }}>*</span> :
                        </td>
                        <td className="user-profile-content">
                          {editBtnClicked ? (
                            <>
                              <input
                                id="phone"
                                name="phone"
                                value={userData ? userData.phone : ""}
                                onChange={(e) => handleInputDataChange(e)}
                              />
                              {phoneError && (
                                <span className="validation-text">
                                  {phoneError}
                                </span>
                              )}
                            </>
                          ) : userData ? (
                            userData.phone
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="user-profile-label">Social Media :</td>
                        <td className="user-profile-content">
                          <img
                            src={facebook}
                            onClick={() => handleImageClick("Facebook")}
                            alt="Facebook"
                            className="pr-2"
                          />
                          <img
                            src={instagram}
                            onClick={() => handleImageClick("Instagram")}
                            alt="Instagram"
                          />
                          <img
                            src={linkedin}
                            onClick={() => handleImageClick("LinkedIn")}
                            alt="LinkedIn"
                          />
                        </td>
                      </tr>
                      <tr>
                        {/* <td className="user-profile-label">
                          Get Notifications on :
                        </td>
                        <td className="user-profile-content">
                          <label className="custom-label">
                            {preferences.whatsapp ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("whatsapp")}
                            />
                            WhatsApp
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                            {preferences.sms ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("sms")}
                            />
                            SMS
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                            {preferences.slack ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("slack")}
                            />
                            Slack
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label className="custom-label">
                            {preferences.email ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("email")}
                            />
                            Email
                          </label>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={"12"} lg={"8"} md={"12"}>
            <Card className="" id="security">
              <CardBody>
                <Row>
                  <Col md={12} id="user-profile">
                    <div className="">
                      <h2 className="heading">
                        
                        <span className="heading-text">
                          Security Information
                        </span>
                      </h2>
                    </div>
                  </Col>
                  <table className="user-profile-table">
                    <tbody>
                      <tr>
                        <td className="user-profile-label">Password :</td>
                        <td className="user-profile-content">                    
                          <span className="float-right" onClick={()=>setIsUpdatePSWUpdateOpen(true)}>
                          <FontAwesomeIcon icon={faEdit} className="edit-icon" title="Edit" />
                        </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> 

        
        <Row className="mt-4">
          <Col xl={"12"} lg={"8"} md={"12"}>
            <Card className="" id="subscription">
              <CardBody>
                <Row>
                  <Col md={12} id="user-profile">
                    <div className="">
                      <h2 className="heading">
                        
                        <span className="heading-text">
                          Notification Subscriptions 
                        </span>
                      </h2>
                    </div>
                  </Col>
                  <table className="user-profile-table">
                    <tbody>
                      <tr>
                        <td className="user-profile-label">
                          Tickets :
                        </td>
                        <td className="user-profile-content">
                          <label className="custom-label">
                            {preferences.Create ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.Create}
                              onChange={() => handleCheckboxChange("Create")}
                            />
                            Create
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                            {console.log(preferences.TicketUpdated)}
                            {preferences.TicketUpdated  ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.TicketUpdated}
                              onChange={() => handleCheckboxChange("TicketUpdated")}
                            />
                            Ticket Update
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                              <img src={checkbox} alt="Checked" />

                            &nbsp;
                            Need Info
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label className="custom-label">
                              <img src={checkbox} alt="Checked" />
                            &nbsp;
                            Informed
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label className="custom-label">
                            {preferences.AmazonCaseRaised ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.AmazonCaseRaised}
                              onChange={() => handleCheckboxChange("AmazonCaseRaised")}
                            />
                            Amazon Case Raised
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label className="custom-label">
                            {preferences.AmazonResponseReceived ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.AmazonResponseReceived}
                              onChange={() => handleCheckboxChange("AmazonResponseReceived")}
                            />
                            Amazon Responce Received
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                            {preferences.Resolved ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.Resolved}
                              onChange={() => handleCheckboxChange("Resolved")}
                            />
                            Resolved
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label className="custom-label">
                            {preferences.Closed ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.Closed}
                              onChange={() => handleCheckboxChange("Closed")}
                            />
                            Closed
                          </label>
                          <span
                            className="float-right"
                            style={{
                              cursor: saveButton ? 'not-allowed' : 'pointer',
                              opacity: saveButton ? 0.5 : 1,
                            }}
                            disabled={saveButton? true:false}
                            onClick={() => updateNotificationStatusData('Ticket') }
                          >
                            <FontAwesomeIcon icon={faSave} className="edit-icon" title="save" />
                          </span>
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="user-profile-label">
                          Workflow Notifications :
                        </td>
                        <td className="user-profile-content">
                          <label className="custom-label">
                            {preferences.whatsapp ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("whatsapp")}
                            />
                            Create
                          </label>
                          &nbsp; &nbsp; &nbsp;
                          <label className="custom-label">
                            {preferences.sms ? (
                              <img src={checkbox} alt="Checked" />
                            ) : (
                              <img src={vector} alt="Unchecked" />
                            )}
                            &nbsp;
                            <input
                              type="checkbox"
                              className="hidden-checkbox"
                              checked={preferences.whatsapp}
                              onChange={() => handleCheckboxChange("sms")}
                            />
                            Update
                          </label>
                         
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <CustomModal
        className="custom-header"
        header={<p className="custom-heading">Social Media Links</p>}
        isOpen={isPopUpOpen}
        toggle={() => setIsPopUpOpen(!isPopUpOpen)}
        size={"lg"}
      >
        <Label>
          <Form>
            <FormGroup>
              <div class="input-container">
                <img src={facebook} className="img-with-background pl-3 pr-3" />
                <Input
                  type="text"
                  id="facebookurl"
                  className="input-box"
                  placeholder="Paste your URL here"
                  value={
                    socialMediaUrls.facebookurl
                      ? socialMediaUrls.facebookurl
                      : ""
                  }
                  onChange={(e) =>
                    handleInputChange("facebookurl", e.target.value)
                  }
                />
              </div>
              <p className="validation-text mb-0 pb-0 text-center">
                {isFacebookValidUrl ? isFacebookValidUrl : ""}
              </p>
            </FormGroup>
            <FormGroup>
              <div class="input-container">
                <img src={instagram} className="img-with-background" />
                <Input
                  type="text"
                  id="instagramUrl"
                  className="input-box"
                  placeholder="Paste your URL here"
                  value={
                    socialMediaUrls.instagramurl
                      ? socialMediaUrls.instagramurl
                      : ""
                  }
                  onChange={(e) =>
                    handleInputChange("instagramurl", e.target.value)
                  }
                />
              </div>
              <p className="validation-text mb-0 pb-0 text-center">
                {isInstagramValidUrl ? isInstagramValidUrl : ""}
              </p>
            </FormGroup>
            <FormGroup>
              <div class="input-container">
                <img src={linkedin} className="img-with-background" />
                <Input
                  type="text"
                  id="linkedinUrl"
                  className="input-box"
                  placeholder="Paste your URL here"
                  value={
                    socialMediaUrls.linkedinurl
                      ? socialMediaUrls.linkedinurl
                      : ""
                  }
                  onChange={(e) =>
                    handleInputChange("linkedinurl", e.target.value)
                  }
                />
              </div>
              <p className="validation-text mb-0 pb-0 text-center">
                {isLinkedinValidUrl ? isLinkedinValidUrl : ""}
              </p>
            </FormGroup>
            <p className="text-center save-btn">
              <Button
                className="user-profile-btn mx-auto"
                type="submit"
                onClick={handleSocilMediaFormSubmit}
              >
                Save
              </Button>
            </p>
          </Form>
        </Label>
      </CustomModal>
      {/* <CustomModal
          isOpen={showConfirmationPopup}
          toggle={() => setShowConfirmationPopup(!showConfirmationPopup)}
          size="md"
        >
          <Label className=''>
            <p>Are you sure you want to save the changes?</p>
            <p className="text-right">
              <Button onClick={(e) =>handleFormSubmit(e) } className="btn btn-success">Save</Button>
              <Button onClick={() => handleConfirmation()} className="ml-3">Discard</Button>
            </p>
            
          </Label>
        </CustomModal> */}
      <CustomModal
        isOpen={showConfirmationPopup}
        toggle={() => setShowConfirmationPopup(!showConfirmationPopup)}
        size="md"
      >
        <Label className="">
          <p>Are you sure you want to save the changes?</p>
          <p className="text-right">
            <Button
            style={{ width: 85 }}
              onClick={(e) => handleFormSubmit(e)}
              className="btn btn-success"
            >
              Save
            </Button>
            <Button onClick={() => handleConfirmation()} className="ml-3">
              Discard
            </Button>
          </p>
        </Label>
      </CustomModal>
      <CustomModal
      header={"Change Your Password"}
          isOpen={isUpdatePSWUpdateOpen}
          toggle={() => setIsUpdatePSWUpdateOpen(!isUpdatePSWUpdateOpen)}
          size="md"
          className='card-of-problemarea'
        >
          <Card className='p-0 m-3 card-of-problemarea '>
            <Container >
              <div className=" p-0">
            <div className="">
                <div className="text-center mx-auto mb-3">
                </div>
               
                <Form>
                    <Row form className='pt-1 pl-2 pr-2 pb-3'>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="email" style={{color:'black'}}><b>Old Password<span style={{color:'red'}}> *</span> :</b> </Label>
                                <Input
                              type="password"
                              id="oldPassword"
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                              required
                            />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="newPassword" style={{color:'black'}}><b>New Password<span style={{color:'red'}}> *</span> :</b> </Label>
                                <Input
                                     type="password"
                                     id="confirmNewPassword"
                                     value={confirmNewPassword}
                                     onChange={(e) => setConfirmNewPassword(e.target.value)}
                                     required
                                   />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="confirmNewPassword" style={{color:'black'}}><b>Confirm New Password<span style={{color:'red'}}> *</span> :</b></Label>
                                <Input
                                     type="password"
                                     id="newPassword"
                                     value={newPassword}
                                     onChange={(e) => setNewPassword(e.target.value)}
                                     required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                           <Button
                         
                            disabled={!oldPassword || !confirmNewPassword || !newPassword}
                            style={{ backgroundColor: '#B1FA63',
                            color: 'black', 
                            border: 'none', }} 
                             type="submit" onClick={(e)=>handleUpdatePassword(e)}>
                          Change Password
                           </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
            </Container>
          </Card>
        </CustomModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  token: state.loggedUser.token,
  stores: state.accountOverview.landing.adProfiles,
});

export default connect(mapStateToProps)(PersonalInfo);
