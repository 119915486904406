import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { connect } from "react-redux";
import ReactQuill from 'react-quill';
import { Editor, SetInnerHtmlWithClassReplacement } from '../../../../../../../../Utils/Constants';
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ReactTable from 'react-table';
import GetTaskHistoryService from '../../../../../../../../Service/OPSServices/SWInternalServices/GetTaskHistoryService';
import AddHistoryService from '../../../../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';

function ViewTaskHistory(props) {
  const [searchInput, setSearchInput] = useState("");
  const [text, setText] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [historyList, setHistoryList] = useState([]);
  const [taskStatus, setTaskStatus] = useState("");
  const [member, setMember] = useState("");
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [isGridCommentsOpen, setIsGridCommentsOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [saveLoading, setSaveLoading] = useState(false)
  const [completing, setCompleting] = useState(false)
  const [commentsText,setCommentsText]= useState()
  useEffect(() => {
    console.log(props.viewTaskData);
    const data = {
      taskId: props.viewTaskData.SWTeamTaskId
    }
    GetTaskHistoryService(props.token, data).then((responce) => {
      if (responce) {
        console.log('responce')
        console.log(responce)
        setHistoryList(responce.historyList)
      }
    })

  }, [])
  const handleCommentsPopup=(value)=>{
    setIsGridCommentsOpen(true)
    setCommentsText(value)
  }
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const columns = [

    {
      Header: 'Comment',
      accessor: 'note',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0 comment-paragraph cursor-pointer"  onClick={()=>handleCommentsPopup(cellInfo.value)}>{stripHtmlTags(cellInfo.value)}</p>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status', Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Assigned To',
      accessor: 'assignedTo',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),

    },
    {
      Header: 'Created By',
      accessor: 'createdByName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),

    },

  ]
  const filterData = Array.isArray(historyList) ? historyList.filter((row) => {
    const note = (row.note || '').toLowerCase();
    const status = (row.status || '').toLowerCase();
    const assignedTo = (row.assignedTo || '').toLowerCase();
    const createdByName = (row.createdByName || '').toString().toLowerCase();
    return note.includes(searchInput.toLowerCase()) || status.includes(searchInput.toLowerCase()) || assignedTo.includes(searchInput.toLowerCase()) || createdByName.includes(searchInput.toLowerCase())
  }) : [];
  const handleChangeStatus = (e) => {
    setTaskStatus(e.target.value)
  }
  const handleAssignedToChange = (e) => {
    setMember(e.target.value)
  }
  const handleQuillChange = (value) => {
    setText(value);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
};
const handleFileChange = (e) => {
  const files = Array.from(e.target.files);
  setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
};
const handleSubmitHistory = (flag) => {

  const formData = new FormData();
  
  formData.append("taskId", props.viewTaskData.SWTeamTaskId);
  formData.append("status", taskStatus);
  formData.append("note", text);
  formData.append("flag", 0);
  if(flag == 1)
  {
    formData.append("isTaskCompleted", 1);
    setCompleting(true)
  }
  if(flag == 2)
  {
    formData.append("isTaskCompleted", 2);
    setSaveLoading(true)

  }
  formData.append("assignedTo", member?member:props.viewTaskData.assignedTo? props.viewTaskData.assignedTo:'');
  if (selectedFiles.length > 0) 
  {
      for (let i = 0; i < selectedFiles.length; i++) 
      {
          formData.append("taskAttachmentFile", selectedFiles[i]);
      }
  }
  else
  {
      formData.append("taskAttachmentFile",[]);
  }
  console.log(formData);
  AddHistoryService(props.token, formData).then((res) => {
      if (res) {
          console.log(res)
          setSelectedFiles([])
          setTaskStatus('')
          setText('')
          setMember('')
          // setHistoryList(res.historyList)
          props.handleCloseCreateTaskPopup(res.tasklist)
      }
    setCompleting(false)
    setSaveLoading(false)
  })  

}
const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
const downloadFile = async (fileUrl) => {
  console.log(fileUrl)
  try {
    // Extract the file name from the URL
    const fileName = fileUrl.taskAttachmentFile.split('/').pop(); // Gets "7_Day_Deals_BrandProfile_Questionnaire_2.pdf"
    
    // Fetch the file from the URL
    const response = await fetch(fileUrl);
    if (!response.ok) throw new Error(`Network response was not ok for ${fileUrl}`);

    // Convert the response to a blob
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary download link
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = fileName; // Use the extracted file name

    // Append the link to the document body, trigger download, and clean up
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl); // Clean up the blob URL to release memory

  } catch (error) {
    console.error('Failed to download file:', error);
  }
};

// Example usage
// const fileUrl = "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/static/7_Day_Deals_BrandProfile_Questionnaire_2.pdf";
// downloadFile(fileUrl);

const show = (path) => {
  // Extract file extension from the file path
  console.log(path)
  const separatedPath = path.split("/");
  const fileName = separatedPath.pop(); // Get the file name with extension
  const fileExtension = fileName.split(".").pop(); // Get the extension

  // Check for file extensions that should be closed quickly after showing the modal
  const quickCloseExtensions = ["xlsx", "csv", "xls", "docx"];

  // If the file is of a type that requires quick close (like .xlsx, .csv, etc.)
  if (quickCloseExtensions.includes(fileExtension)) {
    setFilePath(path);        // Set the file path for displaying in the iframe
    setIsAttachmentOpen(true); // Open the modal

    // Close the modal after 200 milliseconds
    setTimeout(() => {
      setIsAttachmentOpen(false);
    }, 200);
  } else {
    // For all other file types (e.g., PDFs, images), just show the modal without auto-closing
    setFilePath(path);        // Set the file path for the iframe
    setIsAttachmentOpen(true); // Open the modal
  }
};
const replaceCommentsText = SetInnerHtmlWithClassReplacement(commentsText);


  return (
    <>
      <Card className='card-of-problemarea  p-3'>
        <Row>
          <Col md={6}>
            <Label><b>Task Title : </b>{props.viewTaskData.taskTitle}</Label>
          </Col>
          <Col md={6}>
            <Label><b>Completed Date : </b>{props.viewTaskData.completedAt}</Label>
          </Col>

          <Col md={6} className='mt-1 mb-1'>
            <Label><b>Planned Start Date : </b>{props.viewTaskData.plannedStartDate}</Label>
          </Col>
          <Col md={6}  className='mt-1 mb-1'>
            <Label><b>Planned End Date : </b>{props.viewTaskData.plannedEndDate}</Label>
          </Col>

          <Col md={6}>
            <Label ><b>Assigned To : </b>
              <select
                id='assigned To'
                onChange={(e) => handleAssignedToChange(e)
                }
                className='form-control w-100 mt-2'>
                <option id='' value=''>--Assigned To--</option>
                {props.teamMemberList && props.teamMemberList.map((member) => (
                  <option
                    value={member.employeeId}
                    selected={member.employeeId == props.viewTaskData.assignedTo}
                  >{member.employeeName}</option>
                  
                ))}
              </select></Label>
             
          </Col>
          <Col md={6} >
            <Label ><b>Status : </b>
              <select className='form-control w-100 mt-2'
                id='newTask'
                name='newTask'
                // value={taskStatus}
                onChange={handleChangeStatus}>
                <option id='' value=''>--Select Status--</option>
                {
                  props.statusList && props.statusList.map((status) => (
                    <option value={status.status} selected={status.status === props.viewTaskData.status}>{status.status}</option>
                  ))
                }
              </select>
            </Label>
          </Col>
          <Col className='mt-2' md={6}>

            <p for="Attachment">
              <b>Helpful Screenshots Or Images : </b>
            </p>
            <label
              htmlFor="fileInput"
              className="custom-file-input-label text-center"
            >
              <i className="fas fa-upload"></i>
              <br /> Choose File

              <input
                type="file"
                id="fileInput"
                name="ticketAttachment"
                className="custom-file-input"
                onChange={handleFileChange}
              />
            </label>
            </Col>
            <Col md={6}>
            <span style={{ fontSize: "13px" }}><b>Attached File List :</b></span>
            <ul style={{ color: "green", fontSize: "13px" }}>
              {selectedFiles
                ? selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button
                      className="btn"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <FontAwesomeIcon
                        color="red"
                        size="15px"
                        icon={faWindowClose}
                      />
                    </button>
                  </li>
                ))
                : ""}
            </ul>

          </Col>
         
          <Col md={12} className='mt-2'>
          {/* <div className='mendetory-star'>
            <span style={{ color: 'red' }}>
              <b> * </b>
            </span>
          </div> */}
          <Label><b>Add Note For Tasks <span style={{ color: 'red' }}> *</span></b></Label>
          {/* <div className='star-quell'> */}
            <ReactQuill
              className=""
              theme={'snow'}
              modules={Editor.modules}
              formats={Editor.formats}
              value={text}
              onChange={handleQuillChange}
              bounds={'.quill-prod'}
              placeholder='Note' />
              {/* </div> */}
          </Col>
          <Col md={12} className='mt-2 d-flex justify-content-center'>
            <Button  className='btn-background-color '
             disabled = {!(text) || (completing || saveLoading)}
             onClick={()=>handleSubmitHistory(1)}> {completing ? 'Saving..' :'Complete'} </Button>
            <Button  className='btn-background-color ml-3' 
             disabled = {!(text) || (saveLoading || completing)}
            onClick={()=>handleSubmitHistory(2)}> {saveLoading ? 'Saving..' :'Save'} </Button>
          </Col>
        </Row>
       
      </Card>
      <Card className='card-of-problemarea mt-3 p-2'>
        <Col md={12}> <h5><b>Task History</b></h5></Col>

      <Col md={12} className='d-flex justify-content-between mb-3'>
          <Input type='text'
            className='w-25 '
            placeholder='Search Text'
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </Col>
        <Col md={12}>
          <ReactTable
            data={filterData}
            columns={columns}
            className="-fixed -highlight -striped"
            getTheadProps={() => {
              return {
                style: {
                  overflowY: "hidden",
                  background: "#B1FA63",
                  color: "#243837",
                },
              };
            }}
            defaultPageSize={5}
            noDataText={"No Data Found."}
            SubComponent={(row) => (
              <div className="p-1">
                {console.log(row.original.files)}
                {row.original.files.length > 0 ? (
                  <ReactTable
                    data={row.original.files}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "taskAttachmentFile",
                        Cell: (cellInfo) => (
                          <div className="d-flex justify-content-center w-100">
                          <p className="comment-paragraph-ticket-history" title={cellInfo.value.split('/').pop()}>
                            {cellInfo.value.split('/').pop()}
                            {console.log(cellInfo.value.split('/').pop())}
                          </p>
                        </div>
                        
                        ),
                      },
                      {
                        Header: "Action",
                        accessor: "taskAttachmentFile",
                        Cell: (cellInfo) => {
                          // Extract the file name from the URL
                          const fileName = cellInfo.value.split('/').pop();
                      
                          // Extract the file extension from the file name
                          const fileExtension = fileName.split('.').pop();
                      
                          return (
                            <div className="d-flex justify-content-center w-100">
                              {
                                // Check if the extracted file extension is in the allowed extensions list
                                fileExtensions.includes(fileExtension) ? (
                                  // Show only the download icon if file extension matches
                                  <FontAwesomeIcon
                                    className="ml-3"
                                    style={{ cursor: 'pointer' }}
                                    icon={faDownload}
                                    onClick={() => downloadFile(cellInfo.original)}
                                  />
                                ) : (
                                  // Show both eye and download icons if file extension doesn't match
                                  <>
                                    <FontAwesomeIcon
                                      className="ml-3"
                                      style={{ cursor: 'pointer' }}
                                      icon={faEye}
                                      onClick={() => show(cellInfo.value)}
                                    />
                                    <FontAwesomeIcon
                                      className="ml-3"
                                      style={{ cursor: 'pointer' }}
                                      icon={faDownload}
                                      onClick={() => downloadFile(cellInfo.original)}
                                    />
                                  </>
                                )
                              }
                            </div>
                          );
                        },
                      }
                      
                    ]}
                    getTheadProps={() => {
                      return {
                        style: {
                          overflowY: "hidden",
                          background: "#B1FA63",
                          color: "#243837",
                        },
                      };
                    }}
                    className="-striped -highlight"
                    defaultPageSize={row.original.files.length}
                    noDataText="No Attachments available"
                  />
                ) : (
                  <p className="text center">No Attachments.</p>
                )}
              </div>
            )}
          />
        </Col>
      </Card>
      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'taskAttachmentFile': filePath, 'SWTeamTaskAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
       size={'lg'}
        className='other-bgColor'
      >
        <Label>
          <div>
            <iframe
              src={
                
                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={'Comments'}
        isOpen={isGridCommentsOpen}
        toggle={() => setIsGridCommentsOpen(!isGridCommentsOpen)}
         className='other-bgColor'
       size={'md'}
      >
        <Card className='card-of-problemarea'>
          <Col md={12} className='p-3'>
          <Label>
          <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceCommentsText }} />
        </Label>
          </Col>
        </Card>
       
      </CustomModal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,

  };
};

export default connect(mapStateToProps)(ViewTaskHistory)