import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function GetTaskHistoryService(token,data) {
    return axios.post(`${CURRENT_API_URL}/swinternalsystem/getTaskHistory`,data,{
    headers: {
        'Authorization': token,
    }
    }).then(res => {
        notify.NotifySuccess("All history fetched successfully. Thank You!");       
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching history. please try again later.");
        return false;
    });
}

export default GetTaskHistoryService;