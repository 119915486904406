import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Input, Label, Button } from 'reactstrap'
// import CustomModal from '../../../../../../Components/Modals/CustomModal';
import { connect } from "react-redux";
import CreateNewTask from './CreateNewTask';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';
import ViewTaskHistory from './ViewTaskHistory';
import AddHistoryService from '../../../../../../../../Service/OPSServices/SWInternalServices/AddHistoryService';

function TaskCreatedByMe(props) {
    const [statusFilter, setStatusFilter] = useState('All')
    const [taskList, setTaskList] = useState([])
    const [searchInput, setSearchInput] = useState("");
    const [createTaskPopup, setCreateTaskPopup] = useState(false)
    const [viewTaskPopup, setViewTaskPopup] = useState(false)
    const [taskData, setTaskData] = useState()
    const [taskName, setTaskName] = useState();
    const [openTaskTitle, setOpenTaskTitle] = useState(false)
    const [satusLoading, setSatusLoading] = useState(false)
    const [assignedLoading, setAssingedLoading] = useState(false)
    
    // console.log(props.taskList)
    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value)
    }
    useEffect(() => {
        setTaskList(props.taskList)
        // Logs the task list every time it changes
        console.log(props.taskList);
      }, [props.taskList]);

    const filteredTaskList = taskList && taskList.filter((task) => {
        const matchesStatus = statusFilter === "All" || task.status === statusFilter;
        const matchesSearch = task.taskTitle.toLowerCase().includes(searchInput.toLowerCase());
        const assingnedTo = task.assignedName.toLowerCase().includes(searchInput.toLowerCase());
        const status = task.status.toLowerCase().includes(searchInput.toLowerCase());
        return matchesStatus && (matchesSearch || status || assingnedTo);
    });
  
    const handleVivewTask = (taskData) => {
        setViewTaskPopup(true)
        setTaskData(taskData)
    }
    const handleViewTaskTitle = (task) => {
        setTaskName(task)
        setOpenTaskTitle(true)
    }
    const handleSubmitHistory = (e, task, flag) => {

        const formData = new FormData();
        if (flag == 'assignedTo') {
            formData.append("assignedTo", e.target.value);
            formData.append("note", 'assignedTo');
        }
        else {
            formData.append("assignedTo", task.assignedTo);
        }
        if (flag == 'status') {
            formData.append("status", e.target.value);
            formData.append("note", 'status');
        }
        else {
            formData.append("status", task.status);
        }
        formData.append("taskId", task.SWTeamTaskId);
        formData.append("flag", 1);

        console.log(formData);
        AddHistoryService(props.token, formData).then((res) => {
            if (res) {
                console.log(formData)
                console.log(res)
                // setTaskList(res.tasklist)
                props.setTaskList(res.tasklist)
            }
            setSatusLoading()
            setAssingedLoading()
        })
    }
    const handleCloseCreateTaskPopup=(tasklist)=>{
        props.setTaskList(tasklist)
        console.log(taskList)
        // setTaskList(tasklist)
        setCreateTaskPopup(false)
        setViewTaskPopup(false)
    }
    return (
        <>
            {/* <Card className="card-min-height-workflows mt-3 card-of-problemarea"> */}
                {/* <Col md={12} className='mt-2'>
                    <Button
                    className='btn-background-color'
                    onClick={()=>props.handleBackFunction()}>
                        All Task

                    </Button>
                    </Col>*/}
                <Col md={12} className='d-flex justify-content-between mt-3 mb-3'>
                    <Input type='text'
                        className='w-25 '
                        placeholder='Search Text'
                        onChange={(e) => handleSearchInputChange(e)}
                    />
                    {/* <Button
            className='btn-background-color'
            onClick={() => handleNewTask()}>
            New Task
          </Button> */}

                </Col>
                <Col md={12}>
                    <label className='mr-3'>
                        <input type='radio'
                            name='status'
                            checked={statusFilter === "All"}
                            value={statusFilter}
                            //  checked={status.status}
                            onChange={() => setStatusFilter('All')} />
                        &nbsp;
                        <b>All</b>
                    </label>
                    {props.taskStatus && props.taskStatus.map((status) => (
                        <label className='mr-3'>
                            <input type='radio'
                                name='status'
                                checked={statusFilter === status.status}
                                value={status.status}
                                //  checked={status.status}
                                onChange={() => setStatusFilter(status.status)} />
                            &nbsp;
                            <b>{status.status}</b>
                        </label>
                    ))}

                </Col>
                <Col md={12} className="">
                    <table className="table custom-scroll-table">
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Title</th>
                                <th scope="col">Status</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Planned Start Date</th>
                                <th scope="col">Planned End Date</th>
                                <th scope="col">Created By</th>
                                <th scope="col">Completed At</th>
                            </tr>
                        </thead>
                        <tbody className="tasklist-scrolle">
                            {filteredTaskList &&
                                filteredTaskList.map((task) => (
                                    <tr key={task.SWTeamTaskId} style={{ backgroundColor: task.rowColor ? task.rowColor : '' }}>
                                       <td className="cursor-pointer">
                                        <div className="d-flex justify-content-center">
                                        <p className="mr-2 mb-0" onClick={() => handleVivewTask(task)}>
                                                {task.SWTeamTaskId}
                                            </p>
                                            </div>
                                        </td>
                                        <td className="cursor-pointer">
                                            <div className="d-flex">
                                               
                                                <p
                                                    className="m-0 comment-paragraph"
                                                    onClick={() => handleViewTaskTitle(task.taskTitle)}
                                                >
                                                    {task.taskTitle}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            {satusLoading === task.SWTeamTaskId ? (
                                                <p className="mb-0" style={{ color: "#81e94b" }}>
                                                    Updating..
                                                </p>
                                            ) : (
                                                <select
                                                    name="status"
                                                    id="status"
                                                    className="form-control w-75"
                                                    onChange={(e) => {
                                                        handleSubmitHistory(e, task, "status");
                                                        setSatusLoading(task.SWTeamTaskId);
                                                    }}
                                                >
                                                    <option value="">-- Select status --</option>
                                                    {props.taskStatus &&
                                                        props.taskStatus.map((status) => (
                                                            <option
                                                                value={status.status}
                                                                selected={status.status === task.status}
                                                            >
                                                                {status.status}
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                        </td>
                                        <td>
                                            {assignedLoading === task.SWTeamTaskId ? (
                                                <p className="mb-0" style={{ color: "#81e94b" }}>
                                                    Updating..
                                                </p>
                                            ) : (
                                                <select
                                                    className="form-control w-75"
                                                    name="assignedto"
                                                    id="assignedto"
                                                    onChange={(e) => {
                                                        handleSubmitHistory(e, task, "assignedTo");
                                                        setAssingedLoading(task.SWTeamTaskId);
                                                    }}
                                                >
                                                    <option value="">-- Assigned to --</option>
                                                    {props.teamMemberList &&
                                                        props.teamMemberList.map((member) => (
                                                            <option
                                                                key={member.employeeId}
                                                                value={member.employeeId}
                                                                selected={member.employeeId === task.assignedTo}
                                                            >
                                                                {member.employeeName}
                                                            </option>
                                                        ))}
                                                </select>
                                            )}
                                        </td>
                                        <td>{task.plannedStartDate}</td>
                                        <td>{task.plannedStartDate}</td>
                                        <td>{task.createdBy}</td>
                                        <td>{task.completedAt}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </Col>

            {/* </Card> */}
            <CustomModal
                header={"Task Title"}
                isOpen={createTaskPopup}
                toggle={() => setCreateTaskPopup(!createTaskPopup)}
                size={"lg"}
                className='curve-popup'
            >
                <Card className='card-of-problemarea'>
                    <CreateNewTask
                        teamMemberList={props.teamMemberList}
                        taskList={props.taskList}
                    handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
                    />
                </Card>
            </CustomModal>
            <CustomModal
                header={"Create task"}
                isOpen={openTaskTitle}
                toggle={() => setOpenTaskTitle(!openTaskTitle)}
                size={"md"}
                className='curve-popup'
            >
                <Card className='card-of-problemarea p-2'>
                    <Col md={12} >
                        <p>{taskName}</p>
                    </Col>

                </Card>
            </CustomModal>
            <CustomModal
                header={<>
                    <h6>View Task / <b>Task Id :</b>&nbsp;{taskData && taskData.SWTeamTaskId}</h6>
                </>}
                isOpen={viewTaskPopup}
                toggle={() => setViewTaskPopup(!viewTaskPopup)}
                size={"lg"}
                className='curve-popup'
            >
                <>
                    <ViewTaskHistory
                        teamMemberList={props.teamMemberList}
                        statusList={props.taskStatus}
                        taskList={props.taskList}
                        taskName={taskName}
                        viewTaskData={taskData}
                    handleCloseCreateTaskPopup={handleCloseCreateTaskPopup}
                    />
                </>

            </CustomModal>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,

    };
};
export default connect(mapStateToProps)(TaskCreatedByMe);