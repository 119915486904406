import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Input, Button, CardFooter } from 'reactstrap'
import { connect } from 'react-redux';


function AmazonProductLiveImages(props) {
 
    const [selectedFileObject, setSelectedFileObject] = useState();

  
    return (
        <>
                    <Row className='justify-content-around'>
                        {props.filteredProductList && Object.values(props.filteredProductList).map((asset) => (

                            <Col sm={4} md={3} onClick={() => { setSelectedFileObject(asset);}} >

                                <Card 
                                    style={{ backgroundColor: selectedFileObject && selectedFileObject.id === asset.id ? '#D0EFB1' : '' }} className={` p-2 mt-2 mb-1 `}
                                >
                                    <div>
                                       <a href={asset.itemImage} target='_blank'> <img src={asset.itemImage} alt='not found' width={'100%'} height={'100%'} className='amazon-live-images' /></a>
                                    </div>
                                    <hr/>
                                    <a href={asset.link} target='_blank'><p className='text-center'>{asset.asin}</p></a>
                                </Card>
                            </Col>
                        ))
                        }
                    </Row>

        </>
  )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};
export default connect(mapStateToProps)(AmazonProductLiveImages)
