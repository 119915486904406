import React, { useCallback, useEffect, useState } from "react";
import GetWorkflowMainCategoryService from "../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowMainCategoryService";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import WorkflowPhasesPage from "./Components/WorkflowPhasesPage";
import { useHistory } from "react-router-dom";
import FetchAllTaskByUser from "../../../../../../Service/OPSServices/WorkflowsService/FetchAllTaskByUser";
import ReactTable from "react-table";
function WorkflowsMainPage(props) {
  const [selectedStore, setSelectedStore] = useState("");
  const [workflowMainCategory, setWorkflowMainCategory] = useState("");
  const [workflowPhases, setWorkflowPhases] = useState("");
  const [workflowPhasesList, setWorkflowPhasesList] = useState("");
  const [workflowPhasesPage, setWorkflowPhasesPage] = useState(false);
  const [selectedWorkflowMasterId, SetSelectedWorkflowMasterId] = useState();
  const [userTaskData, setUserTaskData] = useState([]);
  const [allTaskData, setAllTaskData] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [statusCounts, setStatusCounts] = useState([]);
  const [overallStatistics, setOverallStatistics] = useState([]);
  const [searchText, setSearchText]=useState('')
  const [teamMemberList, setTeamMemberList]=useState()
  const [loggedUserId, setLoggedUserId]=useState()
  const [executorId,setExecutorId]=useState()
  const [statusFilter, setStatusFilter] = useState('All')
  const [selectedStoreData, setSelectedStoreData] = useState({
    integerId: "",
    storeName: "",
  });
  useEffect(() => {
    fetchLatestData();
  },[])
  const fetchLatestData = () =>{
    GetWorkflowMainCategoryService(props.token).then((res) => {
      if (res) {
        console.log(res);
        setWorkflowMainCategory(res.masterWorkflows);
        setWorkflowPhases(res.workflowPhases);
      }
    });
    FetchAllTaskByUser(props.token).then((res) => {
      if (res) {
        console.log(res);

//         const filteredTasks = res.taskList.filter(task => task.executor === res.loggedUserId);
//         setUserTaskData(filteredTasks)
//         setAllTaskData(res.taskList)
//         console.log(filteredTasks)

        setTeamMemberList(res.teamMembers)
        setStatistics(res.statistics)
        setStatusCounts(res.statusCounts)
        setExecutorId(res.loggedUserId)
        setLoggedUserId(res.loggedUserId)
        setOverallStatistics(res.overallStatistics)
        const filteredTasks = res.taskList.filter(task => task.executor === res.loggedUserId);
        setUserTaskData(filteredTasks)
        setAllTaskData(res.taskList)
      }
    });
  }

  const handleStoreChange = (e) => {
    console.log(e.target.value);
    props.adProfiles.map((store) => {
      if (e.target.value == store.integerID) {
        setSelectedStore(e.target.value);
        console.log(e.target.value)
        setSelectedStoreData({
          integerId: store.integerID,
          storeName: store.name
        });
      }
    });
  };
  const handleWorkflowMaster = (workflowId) => {
    SetSelectedWorkflowMasterId(workflowId);
    const filteredPhases = workflowPhases && workflowPhases.filter(phase => phase.WorkflowsMaster == workflowId).sort((a, b) => a.id - b.id);
    setWorkflowPhasesList(filteredPhases)
  };

  const handleBackButtonToWorkflow = () => {
    fetchLatestData();
    setWorkflowPhasesPage(false);
    SetSelectedWorkflowMasterId(null)
    setSelectedStore({integerID:''})
    setSelectedStoreData(null)
  };
  const quickOpenWorkflowByStoreName = (data) =>{
    setSelectedStoreData({integerId:data.integerId,storeName:data.storeName})
    handleWorkflowMaster(data.workflowId)
    setWorkflowPhasesPage(true)
  }
  const handaleSearchStoreHealth=(e)=>{
    setSearchText(e.target.value)
  }

  const filterStorehealthAllTasks = allTaskData && allTaskData.filter((row) => {
    
    const filterRadioStatus= statusFilter === 'All'|| statusFilter === row.status
    const assignedToFilter = row.executor == executorId;
    const storeName =( row.storeName ||' ').toLowerCase().includes(searchText.toLowerCase());
    const workFlow =(row.workFlow ||'').toLowerCase().includes(searchText.toLowerCase());
    const phase = (row.phase || '').toLowerCase().includes(searchText.toLowerCase());
    const task =(row.task ||'').toLowerCase().includes(searchText.toLowerCase());
    const status =(row.status ||'').toLowerCase().includes(searchText.toLowerCase());
    const executorName =  (row.executorName ||'').toLowerCase().includes(searchText.toLowerCase());
    if(executorId)
    {
    const assignedToFilter = row.executor == executorId;
    return filterRadioStatus &&  assignedToFilter && (storeName || workFlow || phase || status || executorName || task);
    }
    else
    {
      return filterRadioStatus && (storeName || workFlow || phase || status || executorName || task);
    }
  });

  // const filterStorehealthAllTasks= allTaskData.filter((row)=>{
  //   const storeName =( row.storeName ||' ').toLowerCase();
  //   const workFlow =(row.workFlow ||'').toLowerCase();
  //   const phase = (row.phase || '').toLowerCase();
  //   const task =(row.task ||'').toLowerCase();
  //   const status =(row.status ||'').toLowerCase();
  //   return storeName.includes(searchText.toLowerCase())||workFlow.includes(searchText.toLowerCase())||phase.includes(searchText.toLowerCase())||task.includes(searchText.toLowerCase())||status.includes(searchText.toLowerCase());
  //   // return storeName.includes(searchText.toLowerCase())|| workFlow.includes(searchText.toLowerCase())|| phase.includes(searchText.toLowerCase())||task.includes(toLowerCase())||status.includes(toLowerCase())
  // });

  const columns=[
    {
      Header:' Store Name',
      accessor:'storeName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={()=>{quickOpenWorkflowByStoreName(cellInfo.original)}}>
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Workflow Name',
      accessor:'workflow',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Phase',
      accessor:'phase',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Tasks',
      accessor:'task'
    },
    {
      Header:'Status',
      accessor:'status',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    }

  ]
  const allTasksColumns=[
    {
      Header:' Store Name',
      accessor:'storeName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={()=>{quickOpenWorkflowByStoreName(cellInfo.original)}}>
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Workflow Name',
      accessor:'workflow',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Phase',
      accessor:'phase',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Tasks',
      accessor:'task'
    },
    {
      Header:'Assigned Task',
      accessor:'executorName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header:'Status',
      accessor:'status',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    }

  ]

  const handleChangeAssignedTo=(e)=>{
    setExecutorId(e.target.value)
    console.log(e.target.value)
  }

  return (
    <>
    
        {!workflowPhasesPage ? (
          <Row>
            <Col md={12}>
              <Card className={props.isMyworksScreen == true ? 'd-none' : 'p-4 card-of-problemarea'}>
                <Row>
                  <Col md={12}>
                  <div className="d-flex">
                  <select
                      id="StoreList"
                      name="StoreList"
                      onChange={(e) => handleStoreChange(e)}
                      value={selectedStore.integerID}
                      className="form-control w-25"
                    >
                      <option value="">--Select Store--</option>
                      {props.adProfiles.map((store) => (
                        <option value={store.integerID}> {store.name}</option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleWorkflowMaster(e.target.value)}
                      value={selectedWorkflowMasterId}
                      className="form-control ml-5 w-25"
                    >
                      <option value="">--Select Workflow--</option>
                      {workflowMainCategory &&
                        workflowMainCategory.map((wf) => (
                          // <option value={wf.id} > {wf.name}</option>
                          <option value={wf.id} disabled={!selectedStore || !selectedStoreData}> {wf.name}</option>
                        ))}
                    </select>
                    <Button
                        className="btn-background-color ml-5"
                        onClick={() => setWorkflowPhasesPage(true)}
                        disabled={!selectedWorkflowMasterId || !(selectedStore, selectedStoreData )}
                      >
                        Submit
                      </Button>
                  </div>
                  </Col>
                  
                </Row>
              </Card>

              <Card className="main-card card-of-problemarea mb-1 mt-2">
              <CardBody>
                <Row>
                  <Col md="12">
                    <CardTitle className='mt-2 border mb-0 p-2 text-center' style={{ fontSize: '17px', color: '#243837', backgroundColor: '#B1FA63' }}> Overall Statistics</CardTitle>
                    <div className="d-flex border p-1 justify-content-around">
                    {
                        overallStatistics ?
                          overallStatistics.map((item) => (
                            <div className="  pl-2 pr-2 m-1" >
                              <h5 className="text-center" style={{fontSize: '18px'}}><b>{item.statistics}</b></h5>
                              <h6 className="text-center"><b>{item.count}</b></h6>
                            </div>
                          ))
                          :
                          <p>Loading Statistics...</p>
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
              <Card className="main-card card-of-problemarea mb-1 mt-2">
              <CardBody>
                <Row>
                  <Col md="12">
                    <CardTitle className='mt-2 border mb-0 p-2 text-center' style={{ fontSize: '17px', color: '#243837', backgroundColor: '#B1FA63' }}>Workflow Status</CardTitle>
                    <div className="d-flex border p-1 justify-content-around">
                    {
                        statusCounts ?
                          statusCounts.map((item) => (
                            <div className="border pt-2 pb-2 pl-2 pr-2 m-1" >
                              <h5 className="text-center" style={{fontSize: '18px'}}><b>{item.status}</b></h5>
                              <h6 className="text-center"><b>{item.count}</b></h6>
                            </div>
                          ))
                          :
                          <p>Loading Statistics...</p>
                      }
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

              <Card className=" p-4 mt-2 card-of-problemarea">
              {/* <CardTitle>All Tasks</CardTitle> */}
              <Row>
                <Col md={12}>
                <div className="d-flex">
                <Input

                  type='text'
                  className='w-25 mb-3'
                  placeholder="Search Text"
                  onChange={(e)=>handaleSearchStoreHealth(e)}/>

                    <select
                            className="form-control w-25 ml-3"
                            name="assignedto"
                            id="assignedto"
                          onChange={(e) => {
                            handleChangeAssignedTo(e);
                            
                          }}
                          >
                            <option value="">-- Assigned to --</option>
                            {teamMemberList &&
                              teamMemberList.map((member) => (
                                <option
                                  key={member.employeeId}
                                  value={member.employeeId}
                                  selected={
                                      member.employeeId === loggedUserId
                                  }
                                >
                                  {member.employeeName}
                                </option>
                              ))}
                          </select>
                
                </div>
                <div>
                <label className='mr-3'>
                        <input
                          type='radio'
                          name='status'
                          checked={statusFilter === "All"}
                          value={statusFilter}
                          //  checked={status.status}
                          onChange={() => setStatusFilter('All')}
                          />&nbsp;
                          <b>All</b>
                        </label>
                  {
                    statusCounts && statusCounts.map((item)=>(
                      <label className='mr-3'>
                        <input
                          type='radio'
                          name='status'
                          value={statusFilter === item.status}
                          onChange={() => setStatusFilter(item.status)}
                          />&nbsp;
                          <b>{item.status}</b>
                        </label>
                    ))
                  }
                
                
                </div>
               
                </Col>

              </Row>
             
              <ReactTable
                data={filterStorehealthAllTasks}
                columns={allTasksColumns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}
                noDataText={"No Data Found."}
                />
              </Card>
              <Card className=" p-4 mt-4 card-of-problemarea">
              <CardTitle>All Tasks</CardTitle>
              <Input
                  type='text'
                  className='w-25 mb-3'
                  placeholder="Search Text"
                  onChange={(e)=>handaleSearchStoreHealth(e)}/>
              <ReactTable
                data={filterStorehealthAllTasks}
                columns={allTasksColumns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}

                noDataText={"No Data Found."}
                />
              </Card>
            </Col>
          </Row>
        ) : (
          <WorkflowPhasesPage
            handleBackButtonToWorkflow={handleBackButtonToWorkflow}
            selectedWorkflowMasterId={selectedWorkflowMasterId}
            workflowPhases={workflowPhasesList}
            workflowMainCategory={workflowMainCategory}
            selectedStoreData={selectedStoreData}
          />
        )}
      
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(WorkflowsMainPage);
