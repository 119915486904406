import React, {Fragment, useState, } from "react";
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';
import { Card } from "reactstrap";

function DashboardNav(props) {
    console.log('props')
    console.log(props)
    const handleMenuClick = (item) => {
        console.log(item)
        window.location.href = item.to;
    };
    return (
        <Fragment>
            {/* <h5 className="app-sidebar__heading">{props.heading}</h5> */}
            {/* <MetisMenu
                content={props.menuItems}
                activeLinkId
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                LinkComponent={RouterLink}
            /> */}
           <Card className="ops-dash-sidebar ml-3">
                <div>
                    {props.menuItems &&
                        props.menuItems.map((item, index) => (
                            <div onClick={()=>handleMenuClick(item)} 
                            className="mb-3" key={index}>
                                    <div className={`${window.location.href.includes(item.to) ? 'ops-icons' : 'ops-icons1'}`}>
                                    <span className={item.icon} style={{ fontSize: '30px', fontWeight: 750 }}></span>
                                    </div>
                                <p className="item-label mb-0">{item.label}</p>
                            </div>
                        ))}
                </div>
            </Card>
        </Fragment>
    );
}

export default DashboardNav;
