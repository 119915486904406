import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import BackButton from "../../../../../../../designUtils/Logo/BackButton.svg";
import GetWorkflowTaskService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowTaskService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCloudUploadAlt,
    faCloudDownloadAlt,
    faEye,
    faDownload,
    faPaperclip,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import SaveTaskDetailsService from "../../../../../../../Service/OPSServices/WorkflowsService/SaveTaskDetailsService";
import GetProgressBarDataService from "../../../../../../../Service/OPSServices/WorkflowsService/GetProgressBarDataService";
import ReactTable from "react-table";
import PriveTab from "../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/Prev_Ques_Arrow.svg";
import TaskDetailsComponents from "./TaskDetailsComponents";
import GetAllWorkflowStatusService from "../../../../../../../Service/OPSServices/WorkflowsService/GetAllWorkflowStatusService";
import TaskListDetailsPriviewModeComponents from "./TaskListDetailsPriviewModeComponents";
import GetTaskHistoryDataService from "../../../../../../../Service/OPSServices/WorkflowsService/GetTaskHistoryDataService";
import * as XLSX from 'xlsx';
import notify from '../../../../../../../../src/Notifications/ToastifyActions';
import * as FileSaver from 'file-saver';
import DownloadTaskDataService from "../../../../../../../Service/OPSServices/WorkflowsService/DownloadTaskDataService";
import { SetInnerHtmlWithClassReplacement } from "../../../../../../../Utils/Constants";
function WorkflowPhasesPage(props) {
    const [currentPhaseId, setCurrentPhaseId] = useState(props.workflowPhases && props.workflowPhases[0] ? props.workflowPhases[0].id : 0);
    const [taskList, setTaskList] = useState('');
    const [allTasks, setAllTasks] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [status, setStatus] = useState("");
    const [workflowPhaseCount, setWorkflowPhaseCount] = useState([]);
    const [totalWorkflowPhaseCount, setTotalWorkflowPhaseCount] = useState();
    const [flag, setFlag] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [isOpneTaskListDetails, setIsOpneTaskListDetails] = useState(false);
    const [taskDetails, setTaskDetails] = useState();
    const [statusList, setStatusList] = useState();
    const [isPriviewModeOn, setIsPriviewModeOn] = useState(false);
    const [teamMembers, setTeamMembers] = useState()
    const [member, setMember] = useState()
    const [satusLoading, setSatusLoading] = useState(false)
    const [assignedLoading, setAssingedLoading] = useState(false)
    const [loadding, setLodding] = useState(false);
    const [latestCommentPopup, setLatestCommentPopup] = useState(false);
    const [latestComment, setLatestComment] = useState(false);
    const [isOpenFileListPopup, setIsOpenFileListPopup] = useState(false);
    const [filePath, setFilePath] = useState([])
    const [fileList, setFileList] = useState([])
    const [isOpenFilePopup, setIsOpenFilePopup] = useState(false);
    const [historyListDetails, setHistoryListDetails] = useState([]);


    useEffect(() => {
        console.log(props.workflowPhases)
        setLodding(true)
        let data = {
            integerId: props.selectedStoreData.integerId,
            workflowMasterId: props.selectedWorkflowMasterId
        }
        GetWorkflowTaskService(props.token, data).then((res) => {
            if (res) {

                console.log(res);
                setTeamMembers(res.teamMembers)
                setTaskList(res.tasklist);
                setAllTasks(res.tasklist)
                setLodding(false)
            }
        });
        GetAllWorkflowStatusService(props.token).then((responce) => {
            if (responce) {
                console.log(responce);
                setStatusList(responce.statusList);
            }
        });
        GetProgressBarDataService(props.token, data).then((response) => {
            if (response) {
                console.log(response)
                setWorkflowPhaseCount(response.workflowPhasesCount);
                setTotalWorkflowPhaseCount(response.totalWorkflowPhasesCount);
            }
        });

    }, []);
    const downloadData = () => {
        let data =
        {
            integerId: props.selectedStoreData.integerId,
            workflowMasterId: props.selectedWorkflowMasterId
        }
        DownloadTaskDataService(props.token, data).then((res) => {
            if (res) {
                console.log(res)
                exportToCSVManagedProducts(res.downloadList, props.selectedStoreData.storeName)
            }
        })
    }
    const exportToCSVManagedProducts = (csvData, fileName) => {
        if (!csvData) {
            notify.NotifySuccess('Task data is not available for this store.')
            return true
        }
        // let sheetName = fileName + '_Workflow'
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        console.log(csvData)
        console.log(fileName)
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Workflow Tasks' : ws }, SheetNames: ['Workflow Tasks'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        console.log('before save')
        FileSaver.saveAs(data, fileName + '_Workflow_' + new Date() + fileExtension);
    };
    const handleSearchInputChange = (e) => {
        setTaskList(allTasks);
        setSearchInput(e.target.value);
        filteredTasktHistory(e.target.value);
    };
    const filteredTasktHistory = (input) => {
        const stringQuery = input.toString();
        if (isPriviewModeOn) {
            if (input !== "") {
                const filtered = allTasks.filter((task) => task.taskText.toLowerCase().includes(stringQuery.toLowerCase()));
                setTaskList(filtered);
                console.log(filtered.length)
            }

        }
        else {
            if (input !== "") {
                const filtered = allTasks.filter((task) => task.taskText.toLowerCase().includes(stringQuery.toLowerCase()));
                if (filtered.length > 0) {
                    setCurrentPhaseId(filtered[0].WorkflowPhases);
                    setTaskList(filtered);
                }
                else {
                    setCurrentPhaseId(0)
                    setTaskList(allTasks);
                }
            }
            else {
                setCurrentPhaseId(1)
                setTaskList(allTasks);
                return true
            }
        }
    };

    const handleOpenTaskDetailsPage = (task) => {

        setIsOpneTaskListDetails(true);
        setIsPriviewModeOn(false);
        setTaskDetails(task);
    };
    const handleBackTaskList = () => {
        setIsOpneTaskListDetails(false);
        setIsPriviewModeOn(false);
    };
    const handleStatusChange = (e, task) => {
        setStatus(e.target.value)
        saveTaskDetails('status', e.target.value, task)
    };
    const handleAssignedToChange = (e, task) => {
        setMember(e.target.value)
        saveTaskDetails('assignedTo', e.target.value, task)
    }
    const handlePriviewMode = () => {
        setIsPriviewModeOn(!isPriviewModeOn);
        setIsOpneTaskListDetails(false);
    };
    const saveTaskDetails = (type, value, taskDetails) => {
        if (!taskDetails) {
            return true;
        }

        let data;
        setLoading(true)
        // setSatusLoading(taskDetails.id)
        if (type == 'assignedTo') {
            data = {
                taskId: taskDetails.id,
                taskText: 'assignedTo',
                workflowMasterId: props.selectedWorkflowMasterId,
                assignedTo: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
                workflowPhaseId: currentPhaseId,
                integerId: props.selectedStoreData.integerId,
                status: taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
                attachmentFile: []
            }
        }
        else if (type == 'status') {
            data = {
                taskId: taskDetails.id,
                taskText: 'status',
                workflowMasterId: props.selectedWorkflowMasterId,
                assignedTo: taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
                workflowPhaseId: currentPhaseId,
                integerId: props.selectedStoreData.integerId,
                status: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
                attachmentFile: []
            }
        }
        else {
            return true
        }

        SaveTaskDetailsService(props.token, data).then((res) => {
            if (res.status) {
                console.log(res)
                setTaskDetails()
                setLoading(false)
                setStatus('')
                setFlag(0)
                setMember()
                setComment('')
                setSelectedFiles([])
                setTaskList(res.tasklist.tasklist)
                setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
                setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
                setSatusLoading()
                setAssingedLoading()

            }
        })
    }
    const viewLatestComment = (text) => {
        setLatestCommentPopup(true);
        setLatestComment(text);
    };
    const show = (filelist) => {
        setFileList(filelist)
        if (filelist.length <= 1) {
            filelist.map((files) => {
                if (files.length <= 1) {
                    console.log(files.length)

                    showFile(files[0].filePath)
                }
                else {
                    files.map((file) => {
                        const separatedPath = file.filePath.split("images/");
                        console.log("first");
                        console.log(separatedPath[1]);
                        const ext = separatedPath[1].split(".")[1];
                        console.log(ext);
                        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
                            setIsOpenFileListPopup(true)
                            setTimeout(() => {
                                setIsOpenFilePopup(false);
                            }, 300);
                        }
                        setIsOpenFileListPopup(true)

                    })
                }
            })
        }
        else {
            setIsOpenFileListPopup(true)
        }


    }

    const showFile = (path) => {
        const separatedPath = path.split("images/");
        console.log("first");
        console.log(separatedPath[1]);
        const ext = separatedPath[1].split(".")[1];
        console.log(ext);
        if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
            setFilePath(path)
            setIsOpenFilePopup(true)
            setTimeout(() => {
                setIsOpenFilePopup(false);
            }, 300);
        }
        setFilePath(path)
        setIsOpenFilePopup(true)
    }
    const stripHtmlTags = (html) => {
        if (!html) return '';
        return html.replace(/<[^>]*>/g, '');
      };
    const downloadFile = async (file) => {
        console.log(file)
        if (true) {
            try {
                let fileName = file.filePath.split('images/')[1]
                fileName = fileName.split('.')[0]
                const response = await fetch(file.filePath);
                if (!response.ok) throw new Error(`Network response was not ok for ${file.id}`);

                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = blobUrl;
                downloadLink.download = fileName || 'downloaded_file';
                // downloadLink.download = 'downloaded_file';

                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error('Failed to download files:', error);
            }
        }
    }

    const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
    const flattenedFileList = fileList && fileList.flatMap(item => item);
    const gridComments = SetInnerHtmlWithClassReplacement(latestComment);


    return (
        <>
            <Card className="pr-3 pl-3 pt-1 pb-1 mb-2 mt-2 card-of-problemarea">
                <Row>
                    <Col md={12}>
                        <div className="d-flex justify-content-between">
                            <button
                                onClick={() => {
                                    props.handleBackButtonToWorkflow();
                                }}
                                className={`hover-btn-link back-btn ${isPriviewModeOn?'mb-2':''}`}
                            >
                                <img src={BackButton} alt="not found" />
                            </button>
                            <h5 className="mb-0 mt-2 comment-paragraph-workflows" title={props.selectedStoreData.storeName} style={{cursor:'pointer'}}>
                                <b>{props.selectedStoreData.storeName}</b>
                            </h5>
                            {props.workflowMainCategory.map((workMC) =>
                                workMC.id == props.selectedWorkflowMasterId ? (
                                    <h5 className="mb-0 mt-2">

                                        &nbsp; : &nbsp;<b>{workMC.name} </b>
                                    </h5>
                                ) : (
                                    ""
                                )
                            )}
                            <div className="mt-2 ml-5" >
                                <label className="toggle-switch ml-3">
                                    <input type="checkbox" onChange={() => handlePriviewMode()} />
                                    <span className="dot"></span>
                                </label>
                                <span className="custom-label ml-1 mr-2  mt-1"> View All Tasks</span>
                                {isPriviewModeOn ? <button 
                                title='Download taks data'
                                onClick={() => downloadData()} className="btn ml-5">
                                <FontAwesomeIcon
                                        // className="ml-5"
                                        style={{ cursor: 'pointer',fontSize:'25px'}}
                                        icon={faDownload}
                                        
                                    />
                                </button> : ''}
                            </div>

                            <Input
                                type="text"
                                value={searchInput}
                                disabled={loadding ? true : false}
                                onChange={(e) => handleSearchInputChange(e)}
                                className="form-control w-25 ml-auto"
                                placeholder="Search Tasks "
                            />
                        </div>
                    </Col>

                    <Col md={12} className="p-2">
                        {!isPriviewModeOn ?
                            <div className="d-flex">
                                <>

                                    {props.workflowPhases
                                        ? props.workflowPhases.map((phase) => (
                                            <>
                                                {props.selectedWorkflowMasterId ==
                                                    phase.WorkflowsMaster ? (
                                                    <div
                                                        onClick={() => {
                                                            if (!loadding) {
                                                                setCurrentPhaseId(phase.id);
                                                                setIsOpneTaskListDetails(false);
                                                            }
                                                        }}
                                                        style={{
                                                            cursor: loadding ? "not-allowed" : "pointer",
                                                            opacity: loadding ? 0.5 : 1
                                                        }}
                                                        className={
                                                            phase.id == currentPhaseId
                                                                ? "selected-tab-workflows m-2"
                                                                : "pt-3 pb-3 m-2"
                                                        }
                                                    >
                                                        <p className="text-center p-0 mb-0" style={{ fontSize: '17px' }} key={phase.id}>
                                                            <b>{phase.name}</b>
                                                        </p>
                                                        {workflowPhaseCount
                                                            && workflowPhaseCount.map((count) => (
                                                                <p className="text-center p-0 mb-0">
                                                                    {phase.id === count.WorkflowPhases_id
                                                                        ? count.attemptedTaskCount +
                                                                        " of " +
                                                                        count.taskCount +
                                                                        " Completed"
                                                                        : ""}
                                                                </p>
                                                            ))
                                                        }

                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ))
                                        : ""}
                                </>
                            </div> : <div className="d-flex ml-2">
                                <>
                                    {props.workflowPhases
                                        ? props.workflowPhases.map((phase) => (
                                            <>
                                                {props.selectedWorkflowMasterId ==
                                                    phase.WorkflowsMaster ? (
                                                    <div
                                                        className="selected-tab-workflows-previewmode m-2"
                                                    >
                                                        <p className="text-center p-0 mb-0" style={{ fontSize: '17px' }} key={phase.id}>
                                                            <b>{phase.name}</b>
                                                        </p>
                                                        {workflowPhaseCount
                                                            && workflowPhaseCount.map((count) => (
                                                                <p className="text-center p-0 mb-0 mr-2">
                                                                    {phase.id === count.WorkflowPhases_id
                                                                        ? count.attemptedTaskCount +
                                                                        " of " +
                                                                        count.taskCount +
                                                                        " Completed"
                                                                        : ""}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ))
                                        : ""}
                                </>
                            </div>}
                    </Col>
                </Row>
            </Card>
            {!isPriviewModeOn ? (
                <>
                    {!isOpneTaskListDetails ? (
                        <Card className="card-min-height-workflows  card-of-problemarea">
                            <Col md={12} className="">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Task</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Assigned To</th>
                                            <th scope="col">Last Update</th>
                                            <th scope="col">Latest Comment</th>
                                            {/* <th scope="col">Attachment</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taskList &&
                                            taskList.map((task) => (
                                                <>
                                                    {currentPhaseId === task.WorkflowPhases && (
                                                        <tr key={task.taskId}>
                                                            <td
                                                                style={{ cursor: "pointer", width: "25%" }}
                                                                onClick={() => handleOpenTaskDetailsPage(task)}
                                                            >
                                                                <a href="#">{task.taskText}</a>
                                                            </td>

                                                            <td style={{ width: "20%" }}>
                                                                {satusLoading === task.id ? (
                                                                    <p className="mb-0" style={{ color: "#81e94b" }}>Updating..</p>
                                                                ) : (
                                                                    <>
                                                                        {task.answerObject[0] && task.answerObject[0].status === 6 ? (
                                                                            <div className="mt-1">
                                                                                <span className="signedoff-dropdown">
                                                                                    Signed Off &nbsp; &nbsp;
                                                                                    <FontAwesomeIcon style={{ color: "#fff" }} icon={faCheck} />
                                                                                </span>
                                                                                <p className="ml-2 mt-2 mb-0">
                                                                                    {task.answerObject[0].updatedByName &&
                                                                                        `${task.answerObject[0].updatedByName.split(" ")[0]} ${task.answerObject[0].updatedByName.split(" ")[1][0]
                                                                                        }.`}
                                                                                </p>
                                                                            </div>
                                                                        ) : (
                                                                            <select
                                                                                className={`w-75 ${task.answerObject[0]
                                                                                    ? task.answerObject[0].status === 3
                                                                                        ? "inprogress-dropdown form-control"
                                                                                        : task.answerObject[0].status === 5
                                                                                            ? "completed-dropdown form-control"
                                                                                            : task.answerObject[0].status === 2
                                                                                                ? "notstarted-dropdown form-control"
                                                                                                : task.answerObject[0].status === 1
                                                                                                    ? "notapplicable-dropdown form-control"
                                                                                                    : task.answerObject[0].status === 4
                                                                                                        ? "postpond-dropdown form-control"
                                                                                                        : "form-control"
                                                                                    : "form-control"
                                                                                    }`}
                                                                                name="status"
                                                                                id="status"
                                                                                onChange={(e) => {
                                                                                    handleStatusChange(e, task);
                                                                                    setSatusLoading(task.id);
                                                                                }}
                                                                            >
                                                                                <option style={{ background: "#fff" }} value="">
                                                                                    -- Select status --
                                                                                </option>
                                                                                {statusList &&
                                                                                    statusList.map((status) => (
                                                                                        <option
                                                                                            style={{ background: "#fff" }}
                                                                                            key={status.id}
                                                                                            value={status.id}
                                                                                            selected={
                                                                                                status.id === (task.answerObject[0] && task.answerObject[0].status)
                                                                                            }
                                                                                        >
                                                                                            {status.status}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td style={{ width: "20%" }}>
                                                                {task.answerObject[0] && task.answerObject[0].status === 6 ? (
                                                                    <h6 className="ml-2"> {task.answerObject[0].executorName}</h6>
                                                                ) : (
                                                                    <>
                                                                        {assignedLoading === task.id ? (
                                                                            <p className="mb-0" style={{ color: "#81e94b" }}>Updating..</p>
                                                                        ) : (
                                                                            <select
                                                                                className="form-control w-75"
                                                                                name="assignedto"
                                                                                id="assignedto"
                                                                                onChange={(e) => {
                                                                                    handleAssignedToChange(e, task);
                                                                                    setAssingedLoading(task.id);
                                                                                }}
                                                                            >
                                                                                <option value="">-- Assigned to --</option>
                                                                                {teamMembers &&
                                                                                    teamMembers.map((member) => (
                                                                                        <option
                                                                                            key={member.employeeId}
                                                                                            value={member.employeeId}
                                                                                            selected={
                                                                                                member.employeeId === (task.answerObject[0] && task.answerObject[0].executor)
                                                                                            }
                                                                                        >
                                                                                            {member.employeeName}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>

                                                            <td style={{ width: "20%" }}>{task.lastModified}</td>

                                                            <td style={{ width: "20%" }}>
                                                                <p
                                                                    className="m-0 comment-paragraph-workflows"
                                                                    style={{ color: "#3D85C6", cursor: "pointer" }}
                                                                    onClick={() =>
                                                                        viewLatestComment(task.answerObject[0] && task.answerObject[0].memo)
                                                                    }
                                                                >
                                                                    {stripHtmlTags(task.answerObject[0] && task.answerObject[0].memo)}
                                                                </p>
                                                            </td>

                                                            <td style={{ width: "20%" }}>
                                                                <FontAwesomeIcon
                                                                    className="mr-2"
                                                                    title={task.attachmentList.length == 0 ? 'File not available' : 'Open file'}
                                                                    style={task.attachmentList.length == 0 ? { color: '#c5c3c3' } : { cursor: 'pointer' }}
                                                                    disabled={task.attachmentList.length == 0 ? true : false}
                                                                    icon={faPaperclip}
                                                                    onClick={() => show(task.attachmentList)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                    </tbody>

                                </table>
                            </Col>
                        </Card>
                    ) : (
                        <TaskDetailsComponents
                            taskList={taskList}
                            isOpneTaskListDetails={isOpneTaskListDetails}
                            workflowMainCategory={props.workflowMainCategory}
                            workflowPhases={props.workflowPhases}
                            selectedWorkflowMasterId={props.selectedWorkflowMasterId}
                            currentPhaseId={currentPhaseId}
                            taskDetails={taskDetails}
                            handleBackTaskList={handleBackTaskList}
                            setCurrentPhaseId={setCurrentPhaseId}
                            statusList={statusList}
                            setTaskDetails={setTaskDetails}
                            setTaskList={setTaskList}
                            setAllTasks={setAllTasks}
                            selectedStoreData={props.selectedStoreData}
                            teamMembers={teamMembers}
                            setWorkflowPhaseCount={setWorkflowPhaseCount}
                            setTotalWorkflowPhaseCount={setTotalWorkflowPhaseCount}
                            historyListDetails={historyListDetails}
                        />
                    )}
                </>
            ) : (
                <TaskListDetailsPriviewModeComponents
                    workflowPhases={props.workflowPhases}
                    workflowMainCategory={props.workflowMainCategory}
                    selectedWorkflowMasterId={props.selectedWorkflowMasterId}
                    currentPhaseId={currentPhaseId}
                    taskList={taskList}
                    statusList={statusList}
                    setStatusList={setStatusList}
                    selectedStoreData={props.selectedStoreData}
                    teamMembers={teamMembers}
                    setWorkflowPhaseCount={setWorkflowPhaseCount}
                    setTotalWorkflowPhaseCount={setTotalWorkflowPhaseCount}
                    taskDetails={taskDetails}
                    setTaskList={setTaskList}
                    setAllTasks={setAllTasks}
                    setTaskDetails={setTaskDetails}
                />
            )}
            <CustomModal
                header={"Latest Comment"}
                isOpen={latestCommentPopup}
                toggle={() => setLatestCommentPopup(!latestCommentPopup)}
                size={"lg"}
                className='curve-popup'
                footer={
                    <>
                        <Button
                            color={"link"}
                            onClick={() => setLatestCommentPopup(!latestCommentPopup)}
                        >
                            Cancel
                        </Button>
                    </>
                }
            >
                <Label>
                    <p className="issue-discription-set" dangerouslySetInnerHTML={{__html : gridComments}}/>
                    {/* <p>{latestComment}</p> */}
                </Label>
            </CustomModal>
            <CustomModal
                header={"Task Attachments List"}
                isOpen={isOpenFileListPopup}
                toggle={() => setIsOpenFileListPopup(!isOpenFileListPopup)}
                size={"md"}
                className='curve-popup'
            >
                <Card>
                    <ReactTable
                        data={flattenedFileList}
                        columns={[
                            {
                                Header: "Name",
                                accessor: "actualFileName",
                                Cell: (cellInfo) => (
                                    <div className="d-flex justify-content-left w-100 text-wrap text-break">
                                        {cellInfo.value}
                                    </div>
                                ),
                            },
                            {
                                Header: "Action",
                                accessor: "filePath",
                                width: 150,
                                Cell: (cellInfo) => (
                                    <div className="d-flex justify-content-center w-100">
                                        {
                                            // fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                            fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                                                <FontAwesomeIcon
                                                    className="ml-5"
                                                    style={{ cursor: 'pointer' }}
                                                    icon={faDownload}
                                                    onClick={() => downloadFile(cellInfo.original)}
                                                />
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon
                                                        className="ml-3"
                                                        style={{ cursor: 'pointer' }}
                                                        icon={faEye}
                                                        onClick={() => showFile(cellInfo.value)}
                                                    />
                                                    <FontAwesomeIcon
                                                        className="ml-3"
                                                        style={{ cursor: 'pointer' }}
                                                        icon={faDownload}
                                                        onClick={() => downloadFile(cellInfo.original)}
                                                    />
                                                </>
                                            )
                                        }
                                    </div>
                                ),
                            },
                        ]}
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: "hidden",
                                    background: "#B1FA63",
                                    color: "#243837",
                                },
                            };
                        }}
                        className="-striped -highlight"
                        defaultPageSize={flattenedFileList.length}
                        noDataText="No Attachments available"
                    />
                </Card>
            </CustomModal>
            <CustomModal
                // header={"Task Attachments List"}
                header={<> Attachment
                    <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'filePath': filePath, 'id': 0 })} />
                </>}
                isOpen={isOpenFilePopup}
                toggle={() => setIsOpenFilePopup(!isOpenFilePopup)}
                size={"lg"}
                className='curve-popup'
            >
                <Card>
                    <Col md={12}>
                        <iframe
                            src={
                                filePath ? filePath : ''
                            }
                            title="File Display"
                            width="100%"
                            height="500"
                        ></iframe>

                    </Col>

                </Card>

            </CustomModal>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        storeOverview: state.accountOverview.store.storeOverview,
        storeProducts: state.accountOverview.store.storeProducts,
        newUser: state.loggedUser.newUser,
        needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        enableMobileMenu: state.theme.enableMobileMenu,
        enableClosedSidebar: state.theme.enableClosedSidebar,
        adProfiles: state.accountOverview.landing.adProfiles,
    };
};

export default connect(mapStateToProps)(WorkflowPhasesPage);
